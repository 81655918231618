.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    padding: 0 1rem;
  }
}

.contact-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;

  .contact-details {
    width: 50%;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;

    a:hover {
      text-decoration: underline;
    }
  }

  .form-container {
    width: 50%;
    background-color: #faf7be;
    input,
    textarea,
    button {
      width: 90%;
      display: block;
      padding: 0.5rem;
      border-radius: 5px;
      border: none;
      outline: none;
      margin: 1rem;
    }
    button {
      cursor: pointer;
      padding: 1rem;
      font-size: 1rem;
      background-color: rgb(160, 160, 160);
      color: white;
      transition: 0.2s ease;
      letter-spacing: 5px;

      &:hover {
        background-color: rgb(102, 102, 102);
      }
    }
  }
}

@media (max-width: 750px) {
  .contact-container {
    flex-direction: column;

    .contact-details,
    .form-container {
      width: 100%;
    }
  }
}
