.services-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  .card-wrap {
    padding: 2rem;
    display: flex;
    gap: 2rem;
  }

  .card {
    width: 30%;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2rem;
  }
}

@media (max-width: 800px) {
  .services-section .card-wrap {
    width: 80%;
    flex-direction: column;
    align-items: center;

    .card {
      width: 80%;
    }
  }
}

@media (max-width: 800px) {
  .services-section .card-wrap {
    width: 90%;
  }
}
