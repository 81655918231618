/* Variables */
$bannerColour: #faf7be;
$fontColour: #646464;

// Banner section

.banner {
  background: url("../images/daisies.avif") no-repeat center center/cover;
  position: relative;
  height: 250px;
  width: 100%;
  display: flex;

  &::before {
    content: "";
    background: linear-gradient(90deg, $bannerColour, rgba(0, 0, 0, 0));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .banner-content {
    position: relative;
    padding-left: 3rem;
    color: $fontColour;
    width: 60%;

    h2 {
      font-size: 2.7rem;
      font-weight: 300;
    }
  }
}

@media (max-width: 800px) {
  .banner {
    &::before {
      background: linear-gradient(
        90deg,
        $bannerColour,
        $bannerColour,
        rgba(0, 0, 0, 0)
      );
    }

    .banner-content h2 {
      font-size: 1.4rem;
      font-weight: 700;
    }
  }
}

@media (max-width: 350px) {
  .banner {
    height: 400px;
  }
}
