.main-header {
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
}

.top-line {
  height: 5px;
  width: 10rem;
  background-color: #faf7be;
  margin: auto;
  border-radius: 5px;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;

  img {
    height: 25rem;
    border-radius: 50%;
  }

  .about-wrap {
    display: flex;
    flex-direction: column;
    padding: 0 5rem;
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .home-container {
    padding: 2rem;

    .about-wrap {
      padding: 0 2rem;
    }
  }
}

@media (max-width: 1200px) {
  .home-container img {
    height: 20rem;
  }
}

@media (max-width: 900px) {
  .main-header {
    font-size: 2rem;
  }
  .home-container {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .home-container {
    padding: 2rem 0;
    img {
      height: 14rem;
    }

    .about-wrap {
      padding: 0.5rem;
    }
  }
}
