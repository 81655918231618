.main-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;

  img {
    height: 60px;
    padding-top: 5px;
  }

  .nav-links ul {
    display: flex;

    li {
      list-style: none;
      padding: 0.3rem 1rem;
      font-weight: 700;
      letter-spacing: 5px;
      text-transform: uppercase;
      border-bottom: 4px solid transparent;
      transition: all 0.2s ease-in-out;

      &:hover {
        border-bottom: 4px solid #faf7be;
      }
    }
  }
}

@media (max-width: 500px) {
  .main-nav {
    display: flex;
    flex-direction: column;

    .logo {
      padding-top: 1rem;
    }

    .nav-links ul {
      padding: 0;

      li {
        padding: 0.4rem;
        letter-spacing: inherit;
      }
    }
  }
}
